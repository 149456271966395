import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Container, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#e0e0e0',
  height: '100vh',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  width: '80%',
  maxWidth: '800px',
  margin: '20px 0',
}));

const NewMedicalRecord = () => {
  const navigate = useNavigate();

  const handleNextStep = () => {
    navigate('/clinic/new-medical-record-step2');
  };

  return (
    <StyledContainer>
      <StyledBox>
        <Typography variant="h5" gutterBottom>Historia clínica nueva</Typography>
        <Typography variant="h6" gutterBottom>I ANAMNESIS</Typography>
        <Typography variant="subtitle1" gutterBottom>1. Filiación</Typography>
        <Box component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Tipo Documento</InputLabel>
            <Select label="Tipo Documento" defaultValue="">
              <MenuItem value="DNI">DNI</MenuItem>
              <MenuItem value="Pasaporte">Pasaporte</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField fullWidth label="Número" variant="outlined" />
          </FormControl>
          <Box /> {/* Espacio vacío */}
          <FormControl fullWidth variant="outlined">
            <TextField fullWidth label="Apellido Paterno" variant="outlined" />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField fullWidth label="Apellido Materno" variant="outlined" />
          </FormControl>
          <Box /> {/* Espacio vacío */}
          <FormControl fullWidth variant="outlined">
            <TextField fullWidth label="Nombres" variant="outlined" />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField fullWidth label="Edad" variant="outlined" />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField fullWidth label="Etnia" variant="outlined" />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Raza</InputLabel>
            <Select label="Raza" defaultValue="">
              <MenuItem value="Caucásico">Caucásico</MenuItem>
              <MenuItem value="Negro">Negro</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Lugar Nacimiento</InputLabel>
            <Select label="Lugar Nacimiento" defaultValue="">
              <MenuItem value="Lugar1">Lugar 1</MenuItem>
              <MenuItem value="Lugar2">Lugar 2</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <Typography>1 de 5</Typography>
          <Button variant="contained" color="primary" onClick={handleNextStep}>Siguiente</Button>
        </Box>
      </StyledBox>
    </StyledContainer>
  );
};

export default NewMedicalRecord;
