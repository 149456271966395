// src/utils/axios.js
import axios from 'axios';

// URL base de la API, puede ser desde una variable de entorno
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.evahealths.com/api';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Agregar un interceptor para añadir el token de autenticación a cada solicitud
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authToken');
        if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
