import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

import materialRoutes from "app/views/material-kit/MaterialRoutes";
import MedicalAttentionView from './views/clinic/MedicalAttentionView'; // Importa tu nuevo componente
import NewMedicalRecord from './views/clinic/NewMedicalRecord';
import NewMedicalRecordStep2 from './views/clinic/NewMedicalRecordStep2';
import NewMedicalRecordStep3 from './views/clinic/NewMedicalRecordStep3';
import NewMedicalRecordStep4 from './views/clinic/NewMedicalRecordStep4';

import AvatarView from './views/avatar/AvatarView';

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("app/views/sessions/JwtRegister")));
const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword")));
// E-CHART PAGE
const AppEchart = Loadable(lazy(() => import("app/views/charts/echarts/AppEchart")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
// USER LIST PAGE
const UserList = Loadable(lazy(() => import("app/views/users/UserList")));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      { path: "/dashboard/default", element: <Analytics />, auth: authRoles.admin },
      // e-chart route
      { path: "/charts/echarts", element: <AppEchart />, auth: authRoles.editor },
      // user list route
      { path: "/users", element: <UserList />, auth: authRoles.admin },
      
      { path: '/clinic/medical-attention', element: <MedicalAttentionView /> },
      { path: '/clinic/new-medical-record', element: <NewMedicalRecord /> },
      { path: '/clinic/new-medical-record-step2', element: <NewMedicalRecordStep2 /> },
      { path: '/clinic/new-medical-record-step3', element: <NewMedicalRecordStep3 /> },
      { path: '/clinic/new-medical-record-step4', element: <NewMedicalRecordStep4 /> },

      // avatar view route
      { path: '/avatar', element: <AvatarView /> },
    ]
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="dashboard/default" /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
