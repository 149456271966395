import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'app/utils/axios';
import { Box, Button, TextField, Paper, Typography, Container, Autocomplete } from '@mui/material';
import { styled } from '@mui/system';

// STYLED COMPONENTS
const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#e0e0e0',
  height: '100vh',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  width: '80%',
  maxWidth: '800px',
  margin: '20px 0',
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#3f51b5',
  color: '#fff',
  padding: '10px 20px',
  borderRadius: '8px 8px 0 0',
}));

const SearchField = styled(TextField)(({ theme }) => ({
  marginBottom: '20px',
}));

const NewButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#7b1fa2',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#6a1b9a',
  },
}));

// COMPONENT
const MedicalAttentionView = () => {
  const [users, setUsers] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/users');
        console.log('Response data:', response.data); // Debug: Verifica la respuesta de la API
        const patientUsers = response.data.users.filter(user => user.role && user.role.includes('Patient'));
        setUsers(patientUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleUserSelect = (event, newValue) => {
    console.log('Selected user:', newValue); // Debug: Verifica el usuario seleccionado
    setSelectedUser(newValue);
  };

  const handleNewUser = () => {
    navigate('/clinic/new-medical-record');
  };

  return (
    <StyledContainer>
      <StyledBox>
        <Header>
          <Typography variant="h6">Nueva atención médica</Typography>
        </Header>
        <Box p={2}>
          <Autocomplete
            freeSolo
            options={users}
            getOptionLabel={(option) => option && option.username ? option.username : ''}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onChange={handleUserSelect}
            renderInput={(params) => (
              <SearchField
                {...params}
                fullWidth
                label="Buscar usuario"
                variant="outlined"
              />
            )}
          />
          <Paper elevation={2}>
            {selectedUser && (
              <Box p={2}>
                <Typography>Usuario seleccionado: {selectedUser.username}</Typography>
                <Typography>Rol: {selectedUser.role.join(', ')}</Typography>
              </Box>
            )}
          </Paper>
          <NewButton fullWidth onClick={handleNewUser}>
            Nuevo
          </NewButton>
        </Box>
      </StyledBox>
    </StyledContainer>
  );
};

export default MedicalAttentionView;
