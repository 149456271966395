import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import { Box, CircularProgress, Typography, Avatar, TextField, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '20px',
}));

const Sidebar = styled(Box)(({ theme }) => ({
  flex: '1',
  padding: '20px',
  backgroundColor: '#f5f5f5',
  borderRight: '1px solid #ccc',
  marginRight: '20px',
}));

const Content = styled(Box)(({ theme }) => ({
  flex: '3',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const AvatarView = () => {
  const [userID, setUserID] = useState('');
  const [users, setUsers] = useState([]);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [avatarMetadata, setAvatarMetadata] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/users'); // Asegúrate de tener una ruta para obtener los usuarios
        setUsers(response.data.users);
      } catch (error) {
        console.error('Error al obtener usuarios:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleUserChange = async (event) => {
    const selectedUserID = event.target.value;
    setUserID(selectedUserID);
    setLoading(true);
    setError(null);

    try {
      const avatarResponse = await axiosInstance.get(`/avatar/download-link/${selectedUserID}`);
      const metadataResponse = await axiosInstance.get(`/avatar/info/${selectedUserID}`);
      setAvatarUrl(avatarResponse.data.download_url);
      setAvatarMetadata(metadataResponse.data.avatar_info);
      setLoading(false);
    } catch (error) {
      setError('Error al obtener el avatar.');
      setLoading(false);
    }
  };

  return (
    <Container>
      <Sidebar>
        <TextField
          select
          label="User ID"
          value={userID}
          onChange={handleUserChange}
          fullWidth
          variant="outlined"
          margin="normal"
        >
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.username}
            </MenuItem>
          ))}
        </TextField>
        {avatarMetadata && (
          <Box mt={2}>
            <Typography variant="h6">Metadata</Typography>
            <Typography>ID: {avatarMetadata.id_asset}</Typography>
            <Typography>Path: {avatarMetadata.asset_path}</Typography>
          </Box>
        )}
      </Sidebar>
      <Content>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : avatarUrl ? (
          <Avatar src={avatarUrl} alt="Avatar del usuario" sx={{ width: 300, height: 300 }} />
        ) : (
          <Typography>Selecciona un usuario para ver su avatar.</Typography>
        )}
      </Content>
    </Container>
  );
};

export default AvatarView;
