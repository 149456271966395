// src/views/clinic/NewMedicalRecordStep2.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Container, Typography, MenuItem, Select, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#e0e0e0',
  height: '100vh',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  width: '80%',
  maxWidth: '800px',
  margin: '20px 0',
}));

const NewMedicalRecordStep2 = () => {
  const navigate = useNavigate();

  const handleNextStep = () => {
    navigate('/clinic/new-medical-record-step3');
  };

  return (
    <StyledContainer>
      <StyledBox>
        <Typography variant="h5" gutterBottom>Historia clínica nueva</Typography>
        <Typography variant="h6" gutterBottom>I ANAMNESIS</Typography>
        <Typography variant="subtitle1" gutterBottom>2. Motivo de consulta</Typography>
        <Box component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel shrink>Tiempo enfermedad</InputLabel>
            <Select label="Tiempo enfermedad" defaultValue="">
              <MenuItem value="Corto">Corto</MenuItem>
              <MenuItem value="Largo">Largo</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ gridColumn: 'span 2' }}>
            <Button variant="outlined">Asintomatico</Button>
            <Button variant="outlined">Sintomatico</Button>
          </Box>
          <FormControl fullWidth variant="outlined" sx={{ gridColumn: 'span 3' }}>
            <TextField fullWidth label="Relato" variant="outlined" multiline rows={4} />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel shrink>Síntomas y signos</InputLabel>
            <Select label="Síntomas y signos" defaultValue="">
              <MenuItem value="Cefalea">Cefalea</MenuItem>
              <MenuItem value="Fiebre">Fiebre</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField fullWidth label="Inicio" variant="outlined" />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField fullWidth label="Fin" variant="outlined" />
          </FormControl>
          <Box sx={{ gridColumn: 'span 3', display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary">Agregar</Button>
          </Box>
        </Box>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>N</TableCell>
                <TableCell>Detalle</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Inicio</TableCell>
                <TableCell>Fin</TableCell>
                <TableCell>Días</TableCell>
                <TableCell>Acción</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>Cefalea</TableCell>
                <TableCell>Síntoma</TableCell>
                <TableCell>01/01/2023</TableCell>
                <TableCell>02/01/2023</TableCell>
                <TableCell>1</TableCell>
                <TableCell><Button color="error">Quitar</Button></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <Typography>2 de 5</Typography>
          <Button variant="contained" color="primary" onClick={handleNextStep}>Siguiente</Button>
        </Box>
      </StyledBox>
    </StyledContainer>
  );
};

export default NewMedicalRecordStep2;
