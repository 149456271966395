import React, { useState } from 'react';
import { Box, Button, TextField, Container, Typography, FormControlLabel, Checkbox, Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#e0e0e0',
  height: '100vh',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  width: '80%',
  maxWidth: '800px',
  margin: '20px 0',
}));

const NewMedicalRecordStep4 = () => {
  const [medication, setMedication] = useState({
    no: false,
    si: false,
    details: ''
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === 'no' && checked) {
      setMedication({ no: true, si: false, details: '' });
    } else if (name === 'si' && checked) {
      setMedication({ no: false, si: true, details: medication.details });
    } else {
      setMedication((prev) => ({ ...prev, [name]: checked }));
    }
  };

  const handleNextStep = () => {
    // Aquí navegas a la siguiente vista cuando esté lista
  };

  return (
    <StyledContainer>
      <StyledBox>
        <Typography variant="h5" gutterBottom>Historia clínica nueva</Typography>
        <Typography variant="h6" gutterBottom>I ANAMNESIS</Typography>
        <Typography variant="subtitle1" gutterBottom>3. Medicamentos</Typography>
        <Box component="form">
          <Typography variant="body1" gutterBottom>¿Ha tomado medicamentos en los últimos 2 semanas?</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={medication.no}
                    onChange={handleCheckboxChange}
                    name="no"
                  />
                }
                label="No"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={medication.si}
                    onChange={handleCheckboxChange}
                    name="si"
                  />
                }
                label="Sí, especificar"
              />
            </Grid>
            {medication.si && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  placeholder="Especificar detalles de medicamentos"
                  value={medication.details}
                  onChange={(e) => setMedication({ ...medication, details: e.target.value })}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <Typography>4 de 5</Typography>
          <Button variant="contained" color="primary" onClick={handleNextStep}>Siguiente</Button>
        </Box>
      </StyledBox>
    </StyledContainer>
  );
};

export default NewMedicalRecordStep4;
