import { createContext, useEffect, useReducer } from "react";
import axiosInstance from "../utils/axios";
import { MatxLoading } from "app/components";

const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialized: true, user };
    }

    case "LOGIN": {
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }

    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }

    case "REGISTER": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    try {
      const response = await axiosInstance.post('/login', { email, password });
      const { user, token } = response.data;
      
      localStorage.setItem('authToken', token);
      dispatch({ type: "LOGIN", payload: { user } });
    } catch (error) {
      console.error("Login error: ", error);
      throw new Error(error.response.data.message);
    }
  };

  const register = async (email, username, password, password_confirmation) => {
    try {
      const response = await axiosInstance.post('/register', { email, username, password, password_confirmation });
      const { user, token } = response.data;
      
      localStorage.setItem('authToken', token);
      dispatch({ type: "REGISTER", payload: { user } });
    } catch (error) {
      console.error("Register error: ", error);
      if (error.response && error.response.data && error.response.data.errors) {
        throw new Error(JSON.stringify(error.response.data.errors));
      } else {
        throw new Error(error.message);
      }
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (token) {
          const response = await axiosInstance.get('/profile', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const { user } = response.data;
          dispatch({ type: "INIT", payload: { isAuthenticated: true, user } });
        } else {
          dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
        }
      } catch (err) {
        console.error("Initialization error: ", err);
        dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
      }
    })();
  }, []);

  if (!state.isInitialized) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
