import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Container, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#e0e0e0',
  height: '100vh',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  width: '80%',
  maxWidth: '800px',
  margin: '20px 0',
}));

const NewMedicalRecordStep3 = () => {
  const navigate = useNavigate();

  const handleNextStep = () => {
    navigate('/clinic/new-medical-record-step4');
  };

  return (
    <StyledContainer>
      <StyledBox>
        <Typography variant="h5" gutterBottom>Historia clínica nueva</Typography>
        <Typography variant="h6" gutterBottom>I ANAMNESIS</Typography>
        <Typography variant="subtitle1" gutterBottom>3. Funciones biológicas</Typography>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Apetito:</Typography>
              <TextField fullWidth defaultValue="Normal" variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <Typography>Peso:</Typography>
              <TextField fullWidth defaultValue="Normal" variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <Typography>Sudor:</Typography>
              <TextField fullWidth defaultValue="Normal" variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <Typography>Sueño:</Typography>
              <TextField fullWidth defaultValue="Normal" variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <Typography>Sed:</Typography>
              <TextField fullWidth defaultValue="Normal" variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <Typography>Orina:</Typography>
              <TextField fullWidth defaultValue="Normal" variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <Typography>Deposiciones:</Typography>
              <TextField fullWidth defaultValue="Normal" variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <Typography>Estado de Ánimo:</Typography>
              <TextField fullWidth defaultValue="Normal" variant="outlined" />
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <Typography>3 de 5</Typography>
          <Button variant="contained" color="primary" onClick={handleNextStep}>Siguiente</Button>
        </Box>
      </StyledBox>
    </StyledContainer>
  );
};

export default NewMedicalRecordStep3;
